var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { class: { highlight: _vm.highlight } }, [
    _c("p", { staticClass: "group-title" }, [
      _vm._v("\n    " + _vm._s(_vm.group.name) + "\n  "),
    ]),
    _vm._v(" "),
    _c("p", [_vm._v(_vm._s(_vm.group.description))]),
    _vm._v(" "),
    _c(
      "table",
      { staticClass: "group_questions" },
      [
        _vm._m(0),
        _vm._v(" "),
        _vm._l(_vm.questions, function (question) {
          return _c(
            "tr",
            { key: question.id, ref: question.group + "row", refInFor: true },
            [
              _c("th", [_vm._v(_vm._s(question.ordering + 1))]),
              _vm._v(" "),
              _c("td", [_vm._v(_vm._s(question.question))]),
              _vm._v(" "),
              _c("td", [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.$root.answers[question.group].first_choice,
                      expression: "$root.answers[question.group].first_choice",
                    },
                  ],
                  ref: question.group + "first_choice",
                  refInFor: true,
                  attrs: { type: "radio", id: question.id + "first_choice" },
                  domProps: {
                    value: question.temperament,
                    checked: _vm._q(
                      _vm.$root.answers[question.group].first_choice,
                      question.temperament
                    ),
                  },
                  on: {
                    change: [
                      function ($event) {
                        return _vm.$set(
                          _vm.$root.answers[question.group],
                          "first_choice",
                          question.temperament
                        )
                      },
                      function ($event) {
                        return _vm.unselectFirstChoice(question.group)
                      },
                    ],
                  },
                }),
                _vm._v(" "),
                _c("label", {
                  attrs: { for: question.id + "first_choice" },
                  on: {
                    click: function ($event) {
                      return _vm.toggleFirstChoice(
                        question.group,
                        question.temperament
                      )
                    },
                  },
                }),
              ]),
              _vm._v(" "),
              _c("td", [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.$root.answers[question.group].second_choice,
                      expression: "$root.answers[question.group].second_choice",
                    },
                  ],
                  ref: question.group + "second_choice",
                  refInFor: true,
                  attrs: { type: "radio", id: question.id + "second_choice" },
                  domProps: {
                    value: question.temperament,
                    checked: _vm._q(
                      _vm.$root.answers[question.group].second_choice,
                      question.temperament
                    ),
                  },
                  on: {
                    change: [
                      function ($event) {
                        return _vm.$set(
                          _vm.$root.answers[question.group],
                          "second_choice",
                          question.temperament
                        )
                      },
                      function ($event) {
                        return _vm.unselectSecondChoice(question.group)
                      },
                    ],
                  },
                }),
                _vm._v(" "),
                _c("label", {
                  attrs: { for: question.id + "second_choice" },
                  on: {
                    click: function ($event) {
                      return _vm.toggleSecondChoice(
                        question.group,
                        question.temperament
                      )
                    },
                  },
                }),
              ]),
            ]
          )
        }),
      ],
      2
    ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("tr", [
      _c("td"),
      _vm._v(" "),
      _c("td"),
      _vm._v(" "),
      _c("td", [_vm._v("First Choice")]),
      _vm._v(" "),
      _c("td", [
        _vm._v("\n        Second Choice\n        "),
        _c("br"),
        _vm._v("(Optional)\n      "),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }