<template>
  <div>
    <div class="header">
      <div class="header-inner">
        <img :src="require('../images/header-logo.png').default" alt="Denim Slade PhD">
      </div>
    </div>

    <div id="quiz_app">
      <router-view v-if="temperaments && questions && groups"></router-view>
    </div>

    <div
      v-if="temperaments && questions && groups"
      class="footer"
    >
      <div class="footer-inner">
        <div class="footer-text">© Copyright - Denim L. Slade</div>
      </div>
    </div>
  </div>
</template>

<script>
import VueRouter from "vue-router";

import QuizIndex from "./quiz_index.vue";
import QuizPage from "./quiz_page.vue";
import Results from "./results.vue";

const routes = [
  { path: "", redirect: "/quiz/0" },
  {
    path: "/quiz/",
    component: QuizIndex,
    children: [
      {
        path: ":page_id",
        component: QuizPage,
        name: "quiz_page"
      }
    ]
  },
  {
    path: "/results/",
    component: Results,
    name: "results"
  }
];

export const router = new VueRouter({ 
  scrollBehavior() {
    return { x: 0, y: 0};
  },
  routes,
});

function shuffleArray(array) {
  for (let i = array.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [array[i], array[j]] = [array[j], array[i]];
  }
}

export default {
  router: router,
  data() {
    return {
      temperaments: null,
      questions: null,
      answers: null,
      groups: null
    };
  },
  async mounted() {
    window.test = "hello";
    let temperaments_response = this.fetch_from_endpoint("temperaments");
    let questions_response = this.fetch_from_endpoint("questions");
    // let answers_response = this.fetch_from_endpoint('answers');
    let groups_response = this.fetch_from_endpoint("groups");

    this.temperaments = await temperaments_response;
    let all_questions = await questions_response;
    // this.answers = await answers_response;
    this.groups = await groups_response;

    // setup answers object
    this.answers = {};
    for (let group of this.groups) {
      this.answers[group.id] = {
        "first_choice": null,
        "second_choice": null,
      };
    }

    for (let group of this.groups) {
      let group_questions = all_questions.filter(
        question => question.group === group.id
      );
      shuffleArray(group_questions);
      for (let question of group_questions) {
        question.ordering = group_questions.indexOf(question);
      }
    }

    this.questions = all_questions;

    let saved_answers = window.localStorage.getItem('why_temperament_saved_answers') || null;
    if (saved_answers) {
      saved_answers = JSON.parse(saved_answers);
      this.answers = saved_answers;
    }

    // dummy data
    // this.answers = {"2":{"first_choice":2,"second_choice":4},"6":{"first_choice":3,"second_choice":1},"7":{"first_choice":3,"second_choice":1},"8":{"first_choice":4,"second_choice":2},"10":{"first_choice":1,"second_choice":4},"11":{"first_choice":1,"second_choice":2},"13":{"first_choice":3,"second_choice":1},"14":{"first_choice":3,"second_choice":1},"16":{"first_choice":2,"second_choice":1},"18":{"first_choice":2,"second_choice":1},"21":{"first_choice":2,"second_choice":4},"23":{"first_choice":2,"second_choice":1}};
  },
  methods: {
    async fetch_from_endpoint(endpoint) {
      let response = await fetch(
        `https://core-temperament.com/api/${endpoint}/`
        // `http://127.0.0.1:8000/api/${endpoint}`
      );
      if (response.ok) {
        return await response.json();
      }
    }
  }
};
</script>

<style lang="less">
#quiz_app {
  max-width: 700px;
  margin: 0 auto;
  text-align: center;

  p {
    margin-top: 0px;
    margin-bottom: 5px;
  }
  label {
    display: block;
  }
  button.option {
    width: 30px;
    height: 30px;
    border-radius: 25px;
    margin-right: 10px;
    background-color: white !important;
    border: #dddddd 4px solid;
    padding: 0px;

    &.active {
      &:nth-of-type(1) {
        background-color: #04a550 !important;
        width: 50px;
        height: 50px;
      }
      &:nth-of-type(2) {
        background-color: #04a550 !important;
        width: 40px;
        height: 40px;
      }
      &:nth-of-type(3) {
        background-color: #dddddd !important;
      }
      &:nth-of-type(4) {
        background-color: #bb1912 !important;
        width: 40px;
        height: 40px;
      }
      &:nth-of-type(5) {
        background-color: #bb1912 !important;
        width: 50px;
        height: 50px;
      }
    }
    &:nth-of-type(1) {
      border-color: #04a550 !important;
      width: 50px;
      height: 50px;
    }
    &:nth-of-type(2) {
      border-color: #04a550 !important;
      width: 40px;
      height: 40px;
    }
    &:nth-of-type(4) {
      border-color: #bb1912 !important;
      width: 40px;
      height: 40px;
    }
    &:nth-of-type(5) {
      border-color: #bb1912 !important;
      width: 50px;
      height: 50px;
    }
  }
  .btn-primary {
    margin-top: 40px;
  }
}
body {
    font-family: 'Montserrat',Helvetica,Arial,serif;
    color: #0d293b;
    line-height: 1.7em;
}
table {
    line-height: 1.7em;
}
.group-title {
    text-transform: uppercase;
    padding-top: 40px;
    font-weight: 600;font-size: 37px;padding-bottom: 10px;letter-spacing: 2px;
    line-height: 45px;
}
button {
    border-width: 0px!important;
    border-radius: 0px;
    letter-spacing: 1px;
    font-size: 16px;
    font-family: 'Montserrat',Helvetica,Arial,Lucida,sans-serif!important;
    font-weight: 300!important;
    text-transform: uppercase!important;
    background-color: #0D293B;
    padding: 12px 100px;
    color: white;
}
th {
    vertical-align: top;
    font-size: 28px !important;
    line-height: 1.3em;
}
input[type="radio"] {
    display: none;
}
input[type="radio"]:checked + label:before {
    border-color: #18abc8;
    animation: ripple 0.2s linear forwards;
}
input[type="radio"]:checked + label:after {
    transform: scale(1);
}
label {
    position:relative;
    text-align:center;
    max-width: 20px;
    height: 20px;
    margin: 0 auto;
}
label:before, label:after {
    position: absolute;
    content: '';
    display: inline-block;
    border-radius: 50%;
    transition: all .3s ease;
    transition-property: transform, border-color;
}
label:before {
    left: 0;
    top: 0;
    width: 20px;
    height: 20px;
    border: 2px solid #0d293b;
}
label:after {
    top: 5px;
    left: 5px;
    width: 14px;
   height: 14px;
   transform: scale(0);
   background:#18abc8;
}
table.group_questions {border-spacing: initial;}
.row-first-choice {
    background-color: #0D293B !important;
    color: white;
}
.row-second-choice {
    background-color: #fafafa !important;
}
tr td:nth-child(3), tr td:nth-child(4) {
    width:14%;
    text-align:center;
    position:relative;
    text-transform: uppercase;
    font-size: 14px;
    font-weight: bold;
    line-height: 100%;
}

// Header
.header {
  .header-inner {
    max-width: 1170px;
    margin: 0 auto;

    img {
      height: 85px;
    }
  }

  @media (max-width: 800px) {
    text-align: center; 
  }
}

// Footer
.footer {
  background-color: #002639;
  color: white;
  margin: 50 -8px -8px -8px;

  .footer-inner {
    max-width: 1170px;
    margin: 0 auto;

    .footer-text {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 85px;
      letter-spacing: 1px;
      font-weight: 200;
      font-size: 14px;
    }
  }
}
</style>
