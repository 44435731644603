var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _vm.not_finished ? _c("p", [_vm._m(0)]) : _vm._e(),
      _vm._v(" "),
      _vm._l(_vm.groups, function (group) {
        return _c("group_questions", {
          key: group.id,
          attrs: {
            group: group,
            highlight: _vm.unanswered_questions.includes(group.id),
          },
        })
      }),
      _vm._v(" "),
      _c(
        "div",
        [
          _vm.has_prev
            ? _c(
                "router-link",
                {
                  staticClass: "btn btn-default",
                  attrs: {
                    tag: "button",
                    to: {
                      name: "quiz_page",
                      params: { page_id: _vm.page_id - 1 },
                    },
                  },
                },
                [_vm._v("Back")]
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.has_next
            ? _c(
                "button",
                {
                  on: {
                    click: function ($event) {
                      return _vm.check_current_page("next")
                    },
                  },
                },
                [_vm._v("Next")]
              )
            : _c("button", { on: { click: _vm.check_questions_answered } }, [
                _vm._v("Finish"),
              ]),
        ],
        1
      ),
    ],
    2
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("strong", [
      _vm._v("You have some "),
      _c("span", { staticStyle: { color: "#881313" } }, [
        _vm._v("unanswered questions"),
      ]),
      _vm._v(". Remember, every question must have a First Choice."),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }