<template>
  <div :class="{ highlight: highlight }">
    <p class="group-title">
      {{ group.name }}
    </p>
    <p>{{ group.description }}</p>
    <table class="group_questions">
      <tr>
        <td></td>
        <td></td>
        <td>First Choice</td>
        <td>
          Second Choice
          <br />(Optional)
        </td>
      </tr>
      <tr v-for="question in questions" :key="question.id" :ref="question.group + 'row'">
        <th>{{ question.ordering + 1 }}</th>
        <td>{{ question.question }}</td>
        <td>
          <input
            type="radio"
            :ref="question.group + 'first_choice'"
            :id="question.id + 'first_choice'"
            v-model="$root.answers[question.group].first_choice"
            :value="question.temperament"
            @change="unselectFirstChoice(question.group)"
          />
          <label
            :for="question.id + 'first_choice'"
            @click="toggleFirstChoice(question.group, question.temperament)"
          ></label>
        </td>
        <td>
          <input
            type="radio"
            :ref="question.group + 'second_choice'"
            :id="question.id + 'second_choice'"
            v-model="$root.answers[question.group].second_choice"
            :value="question.temperament"
            @change="unselectSecondChoice(question.group)"
          />
          <label
            :for="question.id + 'second_choice'"
            @click="toggleSecondChoice(question.group, question.temperament)"
          ></label>
        </td>
      </tr>
    </table>
  </div>
</template>

<script>
export default {
  props: {
    group: {
      required: true,
    },
    highlight: {
      default: false,
    }
  },
  computed: {
    questions() {
      return this.$root.questions
        .filter(question => question.group === this.group.id)
        .sort((a, b) => a.ordering - b.ordering);
    }
  },
  mounted() {
    for (let question of this.questions) {
      let first_choice = this.$root.answers[question.group].first_choice;
      let second_choice = this.$root.answers[question.group].second_choice;

      let row = this.$refs[question.group + "row"];

      let index = 0;
      let buttons = this.$refs[question.group + "first_choice"];
      for (let button of buttons) {
        if (first_choice == button.value) {
          row[index].classList.add("row-first-choice");
        }
        index++;
      }

      index = 0;
      buttons = this.$refs[question.group + "second_choice"];
      for (let button of buttons) {
        if (second_choice == button.value) {
          row[index].classList.add("row-second-choice");
        }
        index++;
      }
    }
  },
  methods: {
    debug() {
      console.log(this.$root.answers);
    },
    saveToLocalStorage() {
      window.localStorage.setItem('why_temperament_saved_answers', JSON.stringify(this.$root.answers));
    },
    toggleFirstChoice(group, current_temperament) {
      let current_answer = this.$root.answers[group].first_choice;

      let row = this.$refs[group + "row"];
      if (current_answer == current_temperament) {
        this.$root.answers[group].first_choice = null;

        let index = 0;
        let buttons = this.$refs[group + "first_choice"];
        for (let button of buttons) {
          row[index].classList.remove("row-first-choice");
          button.checked = false;
          index++;
        }
      }

      this.saveToLocalStorage();
    },
    toggleSecondChoice(group, current_temperament) {
      let current_answer = this.$root.answers[group].second_choice;

      let row = this.$refs[group + "row"];
      if (current_answer == current_temperament) {
        this.$root.answers[group].second_choice = null;

        let index = 0;
        let buttons = this.$refs[group + "second_choice"];
        for (let button of buttons) {
          row[index].classList.remove("row-second-choice");
          button.checked = false;
          index++;
        }
      }

      this.saveToLocalStorage();
    },
    // Buttons weren't unselecting, so manually did it
    unselectFirstChoice(group) {
      // get current answer
      let current_answer = this.$root.answers[group].first_choice;

      // get row to apply class to
      let row = this.$refs[group + "row"];

      // loop through all buttons in question group
      let index = 0;
      let buttons = this.$refs[group + "first_choice"];
      for (let button of buttons) {
        // unselect each button that isn't the answer
        if (current_answer == button.value) {
          row[index].classList.add("row-first-choice");
          button.checked = true;
        } else {
          row[index].classList.remove("row-first-choice");
          button.checked = false;
        }
        index++;
      }

      // Unselect second choice if first overrides it
      if (current_answer === this.$root.answers[group].second_choice) {
        this.$root.answers[group].second_choice = null;

        let index = 0;
        let buttons = this.$refs[group + "second_choice"];
        for (let button of buttons) {
          row[index].classList.remove("row-second-choice");
          button.checked = false;
          index++;
        }
      }

      this.saveToLocalStorage();
    },
    unselectSecondChoice(group) {
      // get current answer
      let current_answer = this.$root.answers[group].second_choice;

      // get row to apply class to
      let row = this.$refs[group + "row"];

      // loop through all buttons in question group
      let index = 0;
      let buttons = this.$refs[group + "second_choice"];
      for (let button of buttons) {
        // unselect each button that isn't the answer
        if (
          current_answer == button.value &&
          current_answer !== this.$root.answers[group].first_choice
        ) {
          row[index].classList.add("row-second-choice");
          button.checked = true;
        } else {
          row[index].classList.remove("row-second-choice");
          button.checked = false;
        }
        index++;
      }

      // check if current_answer is same as first_choice
      if (current_answer === this.$root.answers[group].first_choice) {
        this.$root.answers[group].second_choice = null;
      }

      this.saveToLocalStorage();
    }
  }
};
</script>

<style lang="less" scoped>
.group_questions {
  padding: 0px 0px 40px 0px;
  border-bottom: 1px solid #ddd;
}

th {
  padding-left: 15px;
  padding-right: 15px;

  font-size: 20px;
}
td,
th {
  padding-top: 15px;
  padding-bottom: 15px;
}
.row-first-choice {
  background-color: lightgreen;
}
.row-second-choice {
  background-color: lightyellow;
}
h2 {
  margin-bottom: 0;
}
.highlight {
  margin: 15px 0px;
  border: 2px solid #881313;
}
</style>
