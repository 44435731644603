<template>
  <div>
    <h1>Congrats!</h1>
    <p>
      You have completed the assessment. If you would like to see your results,
      please enter in your name and email and we will send them to you.
    </p>
    <p v-if="message">{{ message }}</p>
    <div v-show="!showForm" id="chartContainer" style="height: 425px; width: 100%;"></div>
    <div v-if="!showForm">
      <p>Your primary temperament type is: {{ primary_temperament.name }} - {{ primary_temperament.description }}</p>
      <p>Your secondary temperament type is: {{ secondary_temperament.name }} - {{ secondary_temperament.description }}</p>
    </div>
    <form
      v-if="showForm"
      id="show-form"
      @submit.prevent="submitForm"
    >
      <div class="section-title">
        Information (required)
      </div>

      <div class="text-group">
        <div>Name:*</div>
        <input id="name" type="text" v-model="formFields.name" required />
        <div>Email:*</div>
        <input id="email" type="email" v-model="formFields.email" required />
        <div>Phone Number:*</div>
        <input id="phone_number" type="text" v-model="formFields.phone_number" required />
      </div>

      <div class="section-title">
        Demographics (optional)
      </div>

      <div class="subsection-title">Gender:</div>
      <div class="radio-group">
        <input v-model="formFields.gender" value="male" type="radio" name="gender" id="male">
        <label for="male">Male</label>
        <input v-model="formFields.gender" value="female" type="radio" name="gender" id="female">
        <label for="female">Female</label>
        <input v-model="formFields.gender" value="no answer" type="radio" name="gender" id="gender_no_answer">
        <label for="gender_no_answer">Prefer not to answer</label>
      </div>

      <div class="subsection-title">Age:</div>
      <div class="radio-group">
        <input v-model="formFields.age" value="age 0-12" type="radio" name="age" id="age-0-12">
        <label for="age-0-12">0 - 12 years old</label>
        <input v-model="formFields.age" value="age 13-17" type="radio" name="age" id="age-13-17">
        <label for="age-13-17">13 - 17 years old</label>
        <input v-model="formFields.age" value="age 18-30" type="radio" name="age" id="age-18-30">
        <label for="age-18-30">18 - 30 years old</label>
        <input v-model="formFields.age" value="age 31-45" type="radio" name="age" id="age-31-45">
        <label for="age-31-45">31 - 45 years old</label>
        <input v-model="formFields.age" value="age 46-60" type="radio" name="age" id="age-46-60">
        <label for="age-46-60">46 - 60 years old</label>
        <input v-model="formFields.age" value="age 61+" type="radio" name="age" id="age-60">
        <label for="age-60">61+ years old</label>
        <input v-model="formFields.age" value="no answer" type="radio" name="age" id="age_no_answer">
        <label for="age_no_answer">Prefer not to answer</label>
      </div>

      <div class="subsection-title">Ethnicity: "Please specify your ethnicity."</div>
      <div class="radio-group">
        <input v-model="formFields.ethnicity" value="caucasian" type="radio" name="ethnicity" id="caucasian">
        <label for="caucasian">Caucasian</label>
        <input v-model="formFields.ethnicity" value="african american" type="radio" name="ethnicity" id="african_american">
        <label for="african_american">African-American</label>
        <input v-model="formFields.ethnicity" value="latino or hispanic" type="radio" name="ethnicity" id="latino_or_hispanic">
        <label for="latino_or_hispanic">Latino or Hispanic</label>
        <input v-model="formFields.ethnicity" value="asian" type="radio" name="ethnicity" id="asian">
        <label for="asian">Asian</label>
        <input v-model="formFields.ethnicity" value="native american" type="radio" name="ethnicity" id="native_american">
        <label for="native_american">Native American</label>
        <input v-model="formFields.ethnicity" value="hawaiian or pacific islander" type="radio" name="ethnicity" id="hawaiian_or_pacific_islander">
        <label for="hawaiian_or_pacific_islander">Native Hawaiian or Pacific Islander</label>
        <input v-model="formFields.ethnicity" value="two or more" type="radio" name="ethnicity" id="two_or_more">
        <label for="two_or_more">Two or more</label>
        <input v-model="formFields.ethnicity" value="other or unknown" type="radio" name="ethnicity" id="other_or_unknown">
        <label for="other_or_unknown">Other/Unknown</label>
        <input v-model="formFields.ethnicity" value="no answer" type="radio" name="ethnicity" id="ethnicity_no_answer">
        <label for="ethnicity_no_answer">Prefer not to answer</label>
      </div>

      <div class="subsection-title">Education: "What is the highest degree or level of education you have completed?"</div>
      <div class="radio-group">
        <input v-model="formFields.education" value="some high school" type="radio" name="education" id="some_high_school">
        <label for="some_high_school">Some High School</label>
        <input v-model="formFields.education" value="high school" type="radio" name="education" id="high_school">
        <label for="high_school">High School</label>
        <input v-model="formFields.education" value="bachelors degree" type="radio" name="education" id="bachelors_degree">
        <label for="bachelors_degree">Bachelor's Degree</label>
        <input v-model="formFields.education" value="masters degree" type="radio" name="education" id="masters_degree">
        <label for="masters_degree">Master's Degree</label>
        <input v-model="formFields.education" value="phd or higher" type="radio" name="education" id="phd_or_higher">
        <label for="phd_or_higher">Ph. D. or Higher</label>
        <input v-model="formFields.education" value="trade school" type="radio" name="education" id="trade_school">
        <label for="trade_school">Trade School</label>
        <input v-model="formFields.education" value="no answer" type="radio" name="education" id="education_no_answer">
        <label for="education_no_answer">Prefer not to answer</label>
      </div>

      <div class="subsection-title">Marital Status: "Are you married?"</div>
      <div class="radio-group">
        <input v-model="formFields.marital_status" value="yes" type="radio" name="marital_status" id="yes">
        <label for="yes">Yes</label>

        <input v-model="formFields.marital_status" value="no" type="radio" name="marital_status" id="no">
        <label for="no">No</label>

        <input v-model="formFields.marital_status" value="no answer" type="radio" name="marital_status" id="marital_status_no_answer">
        <label for="marital_status_no_answer">Prefer not to answer</label>
      </div>

      <div id="submit-button">
        <button
          type="submit"
          :class="{ disabled: formSubmitting }"
        >submit</button>
      </div>
    </form>
    <div id="restart-button">
      <button @click="restart">restart</button>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      formFields: {
        name: '',
        email: '',
        phone_number: '',
        gender: '',
        age: '',
        ethnicity: '',
        education: '',
        marital_status: ''
      },
      // dummy data
      // formFields: {
      //   name: "Shane Taylor",
      //   email: "shanetaylor320@gmail.com",
      //   phone_number: "435-272-8460",
      //   gender: 'no answer',
      //   age: 'no answer',
      //   ethnicity: 'no answer',
      //   education: 'no answer',
      //   marital_status: 'no answer',
      // },
      formSubmitting: false,
      message: "",
      showForm: true,
      primary_temperament: null,
      secondary_temperament: null,
      temperaments: [
        {},
        {
          name: 'C',
          description: 'Comfort and Connection',
        },
        {
          name: 'O',
          description: 'Optimism',
        },
        {
          name: 'R',
          description: 'Resolution',
        },
        {
          name: 'E',
          description: 'Exactness',
        },
      ]
    };
  },
  methods: {
    showChart() {
      let answers = this.$root.answers;

      let data_points = [ 
        {y: 0, label: "C"},
        {y: 0, label: "O"},
        {y: 0, label: "R"},
        {y: 0, label: "E"},
      ]

      let total = 0;
      let keys = Object.keys(answers);
      keys.forEach((key) => {
        data_points[answers[key].first_choice - 1].y += 10;
        total += 10;
        if (answers[key].second_choice) {
          data_points[answers[key].second_choice - 1].y += 5;
          total += 5;
        }
      });

      for (let i = 0; i < 4; i++) {
        data_points[i].y /= total;
        data_points[i].y *= 100;
      }

      CanvasJS.addColorSet("COREShades",
        [
          "#0a5ea3",
          "#fda414",
          "#e22c0a",
          "#3a4e41",
        ]
      );

      const chart = new CanvasJS.Chart("chartContainer", {
        animationEnabled: true,
        title: {
          text: "Your Results"
        },
        colorSet: "COREShades",
        data: [{
          type: "pie",
          startAngle: 240,
          yValueFormatString: "##0.00\"%\"",
          indexLabel: "{label} {y}",
          dataPoints: data_points
        }]
      });

      chart.render();
    },
    async submitForm() {
      if (this.formSubmitting) return;
      this.formSubmitting = true;

      let scores = {};

      let answers = this.$root.answers;

      // score first_choice
      let keys = Object.keys(answers);
      keys.forEach(key => {
        let first_choice = answers[key].first_choice;
        if (first_choice !== null) {
          if (first_choice in scores) {
            scores[first_choice] += 10;
          } else {
            scores[first_choice] = 10;
          }
        }
      });

      // score second_choice
      keys = Object.keys(answers);
      keys.forEach(key => {
        let second_choice = answers[key].second_choice;
        if (second_choice !== null) {
          if (second_choice in scores) {
            scores[second_choice] += 5;
          } else {
            scores[second_choice] = 5;
          }
        }
      });

      // find highest score
      let largest = 0;
      let primary_temperament = 0;
      keys = Object.keys(scores);
      keys.forEach(key => {
        if (scores[key] > largest) {
          largest = scores[key];
          primary_temperament = key;
        }
      });

      // find second highest score
      largest = 0;
      let secondary_temperament = 0;
      keys = Object.keys(scores);
      keys.forEach(key => {
        if (key != primary_temperament) {
          if (scores[key] > largest) {
            largest = scores[key];
            secondary_temperament = key;
          }
        }
      });

      this.primary_temperament = this.temperaments[primary_temperament];
      this.secondary_temperament = this.temperaments[secondary_temperament];

      const postData = {
        ...this.formFields,
        primary_temperament: primary_temperament,
        secondary_temperament: secondary_temperament,
        results: JSON.stringify(answers)
      };

      if (postData.primary_temperament == 0 || postData.secondary_temperament == 0) {
        this.message = "Invalid data. Please retake the assessment.";
        return;
      }

      const options = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "X-CSRFToken": window.csrfmiddlewaretoken
        },
        body: JSON.stringify(postData)
      };

      const urlToFetch = `https://core-temperament.com/api/users/`;
      // const urlToFetch = `http://127.0.0.1:8000/api/users/`;

      let responseData = await fetch(urlToFetch, options);
      alert("Submitted. Please check your email for more detail about your results.");
      window.location.href = 'https://becomehealthyandhappy.com/core-what-is-next';

      // Not used anymore
      // this.formFields = false;
      // this.formFields = {
      //   name: "",
      //   email: ""
      // };

      // this.message = "Submitted. Please check your email for more detail about your results.";
      // this.showForm = false;
      // this.showChart();
    },
    restart() {
      this.showForm = true;
      window.localStorage.removeItem('why_temperament_saved_answers');
      this.$router.push({
        name: "quiz_page",
        params: {
          page_id: 0,
        }
      });
      location.reload();
    }
  }
};
</script>

<style lang="less" scoped>
.text-group {
  display: grid;
  grid-template-columns: 140px 1fr;
  gap: 8px;
  align-items: center;

  div {
    font-weight: bold;
  }

  input {
    border: 2px solid #0D293B;
    border-radius: 2px;
    padding: 8px 10px;
  }
}

#submit-button {
  margin-top: 30px;

  button {
    width: 100%;
  }

  button:hover {
    cursor: pointer;
  }
}

#restart-button button {
  max-width: 500px;
  width: 100%;
}
#restart-button button:hover {
  cursor: pointer;
}

#submit-button .disabled {
  background-color: grey;
}

#show-form {
  max-width: 500px;
  margin: 20px auto;
  text-align: left;
}

.radio-group {
  display: grid;
  grid-template-columns: 1fr;
  text-align: left;

  label {
    margin: 0 0 5px 60px;
    max-width: none;
    text-align: left;
    height: auto;
  }

  label:before {
    left: -30px;
  }

  label:after {
    left: -25px;
  }
}

#quiz_app p {
  max-width: 500px;
  margin: 0 auto;
  text-align: left;
}

#quiz_app > div {
  padding-top: 20px;
}

.section-title {
  font-size: 20px;
  font-weight: bold;
  margin-top: 30px;
  margin-bottom: 10px;
}

.subsection-title {
  font-weight: bold;
  margin-top: 8px;
  margin-bottom: 5px;
}
</style>
