<template>
  <div>
    <p v-if="not_finished">
      <strong>You have some <span style="color: #881313;">unanswered questions</span>. Remember, every question must have a First Choice.</strong>
    </p>
    <group_questions
      v-for="group in groups"
      :key="group.id"
      :group="group"
      :highlight="unanswered_questions.includes(group.id)"
    ></group_questions>
    <div>
      <router-link
        tag="button"
        class="btn btn-default"
        v-if="has_prev"
        :to="{name: 'quiz_page', params: {page_id: page_id - 1}}"
      >Back</router-link>
      <button v-if="has_next" @click="check_current_page('next')">Next</button>
      <button v-else @click="check_questions_answered">Finish</button>
    </div>
  </div>
</template>

<script>
import group_questions from "./group_questions.vue";

const items_per_page = 3;

export default {
  data() {
    return {
      not_finished: false,
      unanswered_questions: [],
    };
  },
  components: {
    group_questions
  },
  computed: {
    page_id() {
      return parseInt(this.$route.params.page_id);
    },
    unanswered_groups() {
      return this.$route.params.unanswered_groups;
    },
    has_prev() {
      return this.page_id > 0;
    },
    has_next() {
      return this.$root.groups.length > this.end;
    },
    start() {
      return this.page_id * items_per_page;
    },
    end() {
      return this.start + items_per_page;
    },
    groups() {
      return this.$root.groups.slice(this.start, this.end);
    },
  },
  methods: {
    check_current_page(dir) {
      let unanswered_groups = [];
      let passed = true;
      this.groups.forEach(group => {
        if (this.$root.answers[group.id].first_choice == null) {
          passed = false;
          unanswered_groups.push(group.id);
        }
      });
      this.unanswered_questions = unanswered_groups;

      if (passed) {
        this.not_finished = false;

        let page_id = this.page_id;
        if (dir == "next") page_id += 1;
        if (dir == "back") page_id -= 1;

        this.$router.push({
          name: "quiz_page",
          params: {
            page_id: page_id
          }
        });
      } else {
        this.not_finished = true;

        this.$router.push({
          name: "quiz_page",
          params: {
            page_id: this.page_id,
            unanswered_groups: unanswered_groups
          }
        });

        window.scrollTo(0, 0);
      }
    },
    check_questions_answered() {
      let passed = true;

      const keys = Object.keys(this.$root.answers);
      keys.forEach(key => {
        if (this.$root.answers[key].first_choice == null) {
          passed = false;
        }
      });

      if (passed) {
        this.$router.push({ name: "results" });
      } else {
        this.not_finished = true;
      }
    }
  }
};
</script>
