var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c("h1", [_vm._v("Congrats!")]),
    _vm._v(" "),
    _c("p", [
      _vm._v(
        "\n    You have completed the assessment. If you would like to see your results,\n    please enter in your name and email and we will send them to you.\n  "
      ),
    ]),
    _vm._v(" "),
    _vm.message ? _c("p", [_vm._v(_vm._s(_vm.message))]) : _vm._e(),
    _vm._v(" "),
    _c("div", {
      directives: [
        {
          name: "show",
          rawName: "v-show",
          value: !_vm.showForm,
          expression: "!showForm",
        },
      ],
      staticStyle: { height: "425px", width: "100%" },
      attrs: { id: "chartContainer" },
    }),
    _vm._v(" "),
    !_vm.showForm
      ? _c("div", [
          _c("p", [
            _vm._v(
              "Your primary temperament type is: " +
                _vm._s(_vm.primary_temperament.name) +
                " - " +
                _vm._s(_vm.primary_temperament.description)
            ),
          ]),
          _vm._v(" "),
          _c("p", [
            _vm._v(
              "Your secondary temperament type is: " +
                _vm._s(_vm.secondary_temperament.name) +
                " - " +
                _vm._s(_vm.secondary_temperament.description)
            ),
          ]),
        ])
      : _vm._e(),
    _vm._v(" "),
    _vm.showForm
      ? _c(
          "form",
          {
            attrs: { id: "show-form" },
            on: {
              submit: function ($event) {
                $event.preventDefault()
                return _vm.submitForm.apply(null, arguments)
              },
            },
          },
          [
            _c("div", { staticClass: "section-title" }, [
              _vm._v("\n      Information (required)\n    "),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "text-group" }, [
              _c("div", [_vm._v("Name:*")]),
              _vm._v(" "),
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.formFields.name,
                    expression: "formFields.name",
                  },
                ],
                attrs: { id: "name", type: "text", required: "" },
                domProps: { value: _vm.formFields.name },
                on: {
                  input: function ($event) {
                    if ($event.target.composing) return
                    _vm.$set(_vm.formFields, "name", $event.target.value)
                  },
                },
              }),
              _vm._v(" "),
              _c("div", [_vm._v("Email:*")]),
              _vm._v(" "),
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.formFields.email,
                    expression: "formFields.email",
                  },
                ],
                attrs: { id: "email", type: "email", required: "" },
                domProps: { value: _vm.formFields.email },
                on: {
                  input: function ($event) {
                    if ($event.target.composing) return
                    _vm.$set(_vm.formFields, "email", $event.target.value)
                  },
                },
              }),
              _vm._v(" "),
              _c("div", [_vm._v("Phone Number:*")]),
              _vm._v(" "),
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.formFields.phone_number,
                    expression: "formFields.phone_number",
                  },
                ],
                attrs: { id: "phone_number", type: "text", required: "" },
                domProps: { value: _vm.formFields.phone_number },
                on: {
                  input: function ($event) {
                    if ($event.target.composing) return
                    _vm.$set(
                      _vm.formFields,
                      "phone_number",
                      $event.target.value
                    )
                  },
                },
              }),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "section-title" }, [
              _vm._v("\n      Demographics (optional)\n    "),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "subsection-title" }, [_vm._v("Gender:")]),
            _vm._v(" "),
            _c("div", { staticClass: "radio-group" }, [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.formFields.gender,
                    expression: "formFields.gender",
                  },
                ],
                attrs: {
                  value: "male",
                  type: "radio",
                  name: "gender",
                  id: "male",
                },
                domProps: { checked: _vm._q(_vm.formFields.gender, "male") },
                on: {
                  change: function ($event) {
                    return _vm.$set(_vm.formFields, "gender", "male")
                  },
                },
              }),
              _vm._v(" "),
              _c("label", { attrs: { for: "male" } }, [_vm._v("Male")]),
              _vm._v(" "),
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.formFields.gender,
                    expression: "formFields.gender",
                  },
                ],
                attrs: {
                  value: "female",
                  type: "radio",
                  name: "gender",
                  id: "female",
                },
                domProps: { checked: _vm._q(_vm.formFields.gender, "female") },
                on: {
                  change: function ($event) {
                    return _vm.$set(_vm.formFields, "gender", "female")
                  },
                },
              }),
              _vm._v(" "),
              _c("label", { attrs: { for: "female" } }, [_vm._v("Female")]),
              _vm._v(" "),
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.formFields.gender,
                    expression: "formFields.gender",
                  },
                ],
                attrs: {
                  value: "no answer",
                  type: "radio",
                  name: "gender",
                  id: "gender_no_answer",
                },
                domProps: {
                  checked: _vm._q(_vm.formFields.gender, "no answer"),
                },
                on: {
                  change: function ($event) {
                    return _vm.$set(_vm.formFields, "gender", "no answer")
                  },
                },
              }),
              _vm._v(" "),
              _c("label", { attrs: { for: "gender_no_answer" } }, [
                _vm._v("Prefer not to answer"),
              ]),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "subsection-title" }, [_vm._v("Age:")]),
            _vm._v(" "),
            _c("div", { staticClass: "radio-group" }, [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.formFields.age,
                    expression: "formFields.age",
                  },
                ],
                attrs: {
                  value: "age 0-12",
                  type: "radio",
                  name: "age",
                  id: "age-0-12",
                },
                domProps: { checked: _vm._q(_vm.formFields.age, "age 0-12") },
                on: {
                  change: function ($event) {
                    return _vm.$set(_vm.formFields, "age", "age 0-12")
                  },
                },
              }),
              _vm._v(" "),
              _c("label", { attrs: { for: "age-0-12" } }, [
                _vm._v("0 - 12 years old"),
              ]),
              _vm._v(" "),
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.formFields.age,
                    expression: "formFields.age",
                  },
                ],
                attrs: {
                  value: "age 13-17",
                  type: "radio",
                  name: "age",
                  id: "age-13-17",
                },
                domProps: { checked: _vm._q(_vm.formFields.age, "age 13-17") },
                on: {
                  change: function ($event) {
                    return _vm.$set(_vm.formFields, "age", "age 13-17")
                  },
                },
              }),
              _vm._v(" "),
              _c("label", { attrs: { for: "age-13-17" } }, [
                _vm._v("13 - 17 years old"),
              ]),
              _vm._v(" "),
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.formFields.age,
                    expression: "formFields.age",
                  },
                ],
                attrs: {
                  value: "age 18-30",
                  type: "radio",
                  name: "age",
                  id: "age-18-30",
                },
                domProps: { checked: _vm._q(_vm.formFields.age, "age 18-30") },
                on: {
                  change: function ($event) {
                    return _vm.$set(_vm.formFields, "age", "age 18-30")
                  },
                },
              }),
              _vm._v(" "),
              _c("label", { attrs: { for: "age-18-30" } }, [
                _vm._v("18 - 30 years old"),
              ]),
              _vm._v(" "),
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.formFields.age,
                    expression: "formFields.age",
                  },
                ],
                attrs: {
                  value: "age 31-45",
                  type: "radio",
                  name: "age",
                  id: "age-31-45",
                },
                domProps: { checked: _vm._q(_vm.formFields.age, "age 31-45") },
                on: {
                  change: function ($event) {
                    return _vm.$set(_vm.formFields, "age", "age 31-45")
                  },
                },
              }),
              _vm._v(" "),
              _c("label", { attrs: { for: "age-31-45" } }, [
                _vm._v("31 - 45 years old"),
              ]),
              _vm._v(" "),
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.formFields.age,
                    expression: "formFields.age",
                  },
                ],
                attrs: {
                  value: "age 46-60",
                  type: "radio",
                  name: "age",
                  id: "age-46-60",
                },
                domProps: { checked: _vm._q(_vm.formFields.age, "age 46-60") },
                on: {
                  change: function ($event) {
                    return _vm.$set(_vm.formFields, "age", "age 46-60")
                  },
                },
              }),
              _vm._v(" "),
              _c("label", { attrs: { for: "age-46-60" } }, [
                _vm._v("46 - 60 years old"),
              ]),
              _vm._v(" "),
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.formFields.age,
                    expression: "formFields.age",
                  },
                ],
                attrs: {
                  value: "age 61+",
                  type: "radio",
                  name: "age",
                  id: "age-60",
                },
                domProps: { checked: _vm._q(_vm.formFields.age, "age 61+") },
                on: {
                  change: function ($event) {
                    return _vm.$set(_vm.formFields, "age", "age 61+")
                  },
                },
              }),
              _vm._v(" "),
              _c("label", { attrs: { for: "age-60" } }, [
                _vm._v("61+ years old"),
              ]),
              _vm._v(" "),
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.formFields.age,
                    expression: "formFields.age",
                  },
                ],
                attrs: {
                  value: "no answer",
                  type: "radio",
                  name: "age",
                  id: "age_no_answer",
                },
                domProps: { checked: _vm._q(_vm.formFields.age, "no answer") },
                on: {
                  change: function ($event) {
                    return _vm.$set(_vm.formFields, "age", "no answer")
                  },
                },
              }),
              _vm._v(" "),
              _c("label", { attrs: { for: "age_no_answer" } }, [
                _vm._v("Prefer not to answer"),
              ]),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "subsection-title" }, [
              _vm._v('Ethnicity: "Please specify your ethnicity."'),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "radio-group" }, [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.formFields.ethnicity,
                    expression: "formFields.ethnicity",
                  },
                ],
                attrs: {
                  value: "caucasian",
                  type: "radio",
                  name: "ethnicity",
                  id: "caucasian",
                },
                domProps: {
                  checked: _vm._q(_vm.formFields.ethnicity, "caucasian"),
                },
                on: {
                  change: function ($event) {
                    return _vm.$set(_vm.formFields, "ethnicity", "caucasian")
                  },
                },
              }),
              _vm._v(" "),
              _c("label", { attrs: { for: "caucasian" } }, [
                _vm._v("Caucasian"),
              ]),
              _vm._v(" "),
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.formFields.ethnicity,
                    expression: "formFields.ethnicity",
                  },
                ],
                attrs: {
                  value: "african american",
                  type: "radio",
                  name: "ethnicity",
                  id: "african_american",
                },
                domProps: {
                  checked: _vm._q(_vm.formFields.ethnicity, "african american"),
                },
                on: {
                  change: function ($event) {
                    return _vm.$set(
                      _vm.formFields,
                      "ethnicity",
                      "african american"
                    )
                  },
                },
              }),
              _vm._v(" "),
              _c("label", { attrs: { for: "african_american" } }, [
                _vm._v("African-American"),
              ]),
              _vm._v(" "),
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.formFields.ethnicity,
                    expression: "formFields.ethnicity",
                  },
                ],
                attrs: {
                  value: "latino or hispanic",
                  type: "radio",
                  name: "ethnicity",
                  id: "latino_or_hispanic",
                },
                domProps: {
                  checked: _vm._q(
                    _vm.formFields.ethnicity,
                    "latino or hispanic"
                  ),
                },
                on: {
                  change: function ($event) {
                    return _vm.$set(
                      _vm.formFields,
                      "ethnicity",
                      "latino or hispanic"
                    )
                  },
                },
              }),
              _vm._v(" "),
              _c("label", { attrs: { for: "latino_or_hispanic" } }, [
                _vm._v("Latino or Hispanic"),
              ]),
              _vm._v(" "),
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.formFields.ethnicity,
                    expression: "formFields.ethnicity",
                  },
                ],
                attrs: {
                  value: "asian",
                  type: "radio",
                  name: "ethnicity",
                  id: "asian",
                },
                domProps: {
                  checked: _vm._q(_vm.formFields.ethnicity, "asian"),
                },
                on: {
                  change: function ($event) {
                    return _vm.$set(_vm.formFields, "ethnicity", "asian")
                  },
                },
              }),
              _vm._v(" "),
              _c("label", { attrs: { for: "asian" } }, [_vm._v("Asian")]),
              _vm._v(" "),
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.formFields.ethnicity,
                    expression: "formFields.ethnicity",
                  },
                ],
                attrs: {
                  value: "native american",
                  type: "radio",
                  name: "ethnicity",
                  id: "native_american",
                },
                domProps: {
                  checked: _vm._q(_vm.formFields.ethnicity, "native american"),
                },
                on: {
                  change: function ($event) {
                    return _vm.$set(
                      _vm.formFields,
                      "ethnicity",
                      "native american"
                    )
                  },
                },
              }),
              _vm._v(" "),
              _c("label", { attrs: { for: "native_american" } }, [
                _vm._v("Native American"),
              ]),
              _vm._v(" "),
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.formFields.ethnicity,
                    expression: "formFields.ethnicity",
                  },
                ],
                attrs: {
                  value: "hawaiian or pacific islander",
                  type: "radio",
                  name: "ethnicity",
                  id: "hawaiian_or_pacific_islander",
                },
                domProps: {
                  checked: _vm._q(
                    _vm.formFields.ethnicity,
                    "hawaiian or pacific islander"
                  ),
                },
                on: {
                  change: function ($event) {
                    return _vm.$set(
                      _vm.formFields,
                      "ethnicity",
                      "hawaiian or pacific islander"
                    )
                  },
                },
              }),
              _vm._v(" "),
              _c("label", { attrs: { for: "hawaiian_or_pacific_islander" } }, [
                _vm._v("Native Hawaiian or Pacific Islander"),
              ]),
              _vm._v(" "),
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.formFields.ethnicity,
                    expression: "formFields.ethnicity",
                  },
                ],
                attrs: {
                  value: "two or more",
                  type: "radio",
                  name: "ethnicity",
                  id: "two_or_more",
                },
                domProps: {
                  checked: _vm._q(_vm.formFields.ethnicity, "two or more"),
                },
                on: {
                  change: function ($event) {
                    return _vm.$set(_vm.formFields, "ethnicity", "two or more")
                  },
                },
              }),
              _vm._v(" "),
              _c("label", { attrs: { for: "two_or_more" } }, [
                _vm._v("Two or more"),
              ]),
              _vm._v(" "),
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.formFields.ethnicity,
                    expression: "formFields.ethnicity",
                  },
                ],
                attrs: {
                  value: "other or unknown",
                  type: "radio",
                  name: "ethnicity",
                  id: "other_or_unknown",
                },
                domProps: {
                  checked: _vm._q(_vm.formFields.ethnicity, "other or unknown"),
                },
                on: {
                  change: function ($event) {
                    return _vm.$set(
                      _vm.formFields,
                      "ethnicity",
                      "other or unknown"
                    )
                  },
                },
              }),
              _vm._v(" "),
              _c("label", { attrs: { for: "other_or_unknown" } }, [
                _vm._v("Other/Unknown"),
              ]),
              _vm._v(" "),
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.formFields.ethnicity,
                    expression: "formFields.ethnicity",
                  },
                ],
                attrs: {
                  value: "no answer",
                  type: "radio",
                  name: "ethnicity",
                  id: "ethnicity_no_answer",
                },
                domProps: {
                  checked: _vm._q(_vm.formFields.ethnicity, "no answer"),
                },
                on: {
                  change: function ($event) {
                    return _vm.$set(_vm.formFields, "ethnicity", "no answer")
                  },
                },
              }),
              _vm._v(" "),
              _c("label", { attrs: { for: "ethnicity_no_answer" } }, [
                _vm._v("Prefer not to answer"),
              ]),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "subsection-title" }, [
              _vm._v(
                'Education: "What is the highest degree or level of education you have completed?"'
              ),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "radio-group" }, [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.formFields.education,
                    expression: "formFields.education",
                  },
                ],
                attrs: {
                  value: "some high school",
                  type: "radio",
                  name: "education",
                  id: "some_high_school",
                },
                domProps: {
                  checked: _vm._q(_vm.formFields.education, "some high school"),
                },
                on: {
                  change: function ($event) {
                    return _vm.$set(
                      _vm.formFields,
                      "education",
                      "some high school"
                    )
                  },
                },
              }),
              _vm._v(" "),
              _c("label", { attrs: { for: "some_high_school" } }, [
                _vm._v("Some High School"),
              ]),
              _vm._v(" "),
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.formFields.education,
                    expression: "formFields.education",
                  },
                ],
                attrs: {
                  value: "high school",
                  type: "radio",
                  name: "education",
                  id: "high_school",
                },
                domProps: {
                  checked: _vm._q(_vm.formFields.education, "high school"),
                },
                on: {
                  change: function ($event) {
                    return _vm.$set(_vm.formFields, "education", "high school")
                  },
                },
              }),
              _vm._v(" "),
              _c("label", { attrs: { for: "high_school" } }, [
                _vm._v("High School"),
              ]),
              _vm._v(" "),
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.formFields.education,
                    expression: "formFields.education",
                  },
                ],
                attrs: {
                  value: "bachelors degree",
                  type: "radio",
                  name: "education",
                  id: "bachelors_degree",
                },
                domProps: {
                  checked: _vm._q(_vm.formFields.education, "bachelors degree"),
                },
                on: {
                  change: function ($event) {
                    return _vm.$set(
                      _vm.formFields,
                      "education",
                      "bachelors degree"
                    )
                  },
                },
              }),
              _vm._v(" "),
              _c("label", { attrs: { for: "bachelors_degree" } }, [
                _vm._v("Bachelor's Degree"),
              ]),
              _vm._v(" "),
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.formFields.education,
                    expression: "formFields.education",
                  },
                ],
                attrs: {
                  value: "masters degree",
                  type: "radio",
                  name: "education",
                  id: "masters_degree",
                },
                domProps: {
                  checked: _vm._q(_vm.formFields.education, "masters degree"),
                },
                on: {
                  change: function ($event) {
                    return _vm.$set(
                      _vm.formFields,
                      "education",
                      "masters degree"
                    )
                  },
                },
              }),
              _vm._v(" "),
              _c("label", { attrs: { for: "masters_degree" } }, [
                _vm._v("Master's Degree"),
              ]),
              _vm._v(" "),
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.formFields.education,
                    expression: "formFields.education",
                  },
                ],
                attrs: {
                  value: "phd or higher",
                  type: "radio",
                  name: "education",
                  id: "phd_or_higher",
                },
                domProps: {
                  checked: _vm._q(_vm.formFields.education, "phd or higher"),
                },
                on: {
                  change: function ($event) {
                    return _vm.$set(
                      _vm.formFields,
                      "education",
                      "phd or higher"
                    )
                  },
                },
              }),
              _vm._v(" "),
              _c("label", { attrs: { for: "phd_or_higher" } }, [
                _vm._v("Ph. D. or Higher"),
              ]),
              _vm._v(" "),
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.formFields.education,
                    expression: "formFields.education",
                  },
                ],
                attrs: {
                  value: "trade school",
                  type: "radio",
                  name: "education",
                  id: "trade_school",
                },
                domProps: {
                  checked: _vm._q(_vm.formFields.education, "trade school"),
                },
                on: {
                  change: function ($event) {
                    return _vm.$set(_vm.formFields, "education", "trade school")
                  },
                },
              }),
              _vm._v(" "),
              _c("label", { attrs: { for: "trade_school" } }, [
                _vm._v("Trade School"),
              ]),
              _vm._v(" "),
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.formFields.education,
                    expression: "formFields.education",
                  },
                ],
                attrs: {
                  value: "no answer",
                  type: "radio",
                  name: "education",
                  id: "education_no_answer",
                },
                domProps: {
                  checked: _vm._q(_vm.formFields.education, "no answer"),
                },
                on: {
                  change: function ($event) {
                    return _vm.$set(_vm.formFields, "education", "no answer")
                  },
                },
              }),
              _vm._v(" "),
              _c("label", { attrs: { for: "education_no_answer" } }, [
                _vm._v("Prefer not to answer"),
              ]),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "subsection-title" }, [
              _vm._v('Marital Status: "Are you married?"'),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "radio-group" }, [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.formFields.marital_status,
                    expression: "formFields.marital_status",
                  },
                ],
                attrs: {
                  value: "yes",
                  type: "radio",
                  name: "marital_status",
                  id: "yes",
                },
                domProps: {
                  checked: _vm._q(_vm.formFields.marital_status, "yes"),
                },
                on: {
                  change: function ($event) {
                    return _vm.$set(_vm.formFields, "marital_status", "yes")
                  },
                },
              }),
              _vm._v(" "),
              _c("label", { attrs: { for: "yes" } }, [_vm._v("Yes")]),
              _vm._v(" "),
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.formFields.marital_status,
                    expression: "formFields.marital_status",
                  },
                ],
                attrs: {
                  value: "no",
                  type: "radio",
                  name: "marital_status",
                  id: "no",
                },
                domProps: {
                  checked: _vm._q(_vm.formFields.marital_status, "no"),
                },
                on: {
                  change: function ($event) {
                    return _vm.$set(_vm.formFields, "marital_status", "no")
                  },
                },
              }),
              _vm._v(" "),
              _c("label", { attrs: { for: "no" } }, [_vm._v("No")]),
              _vm._v(" "),
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.formFields.marital_status,
                    expression: "formFields.marital_status",
                  },
                ],
                attrs: {
                  value: "no answer",
                  type: "radio",
                  name: "marital_status",
                  id: "marital_status_no_answer",
                },
                domProps: {
                  checked: _vm._q(_vm.formFields.marital_status, "no answer"),
                },
                on: {
                  change: function ($event) {
                    return _vm.$set(
                      _vm.formFields,
                      "marital_status",
                      "no answer"
                    )
                  },
                },
              }),
              _vm._v(" "),
              _c("label", { attrs: { for: "marital_status_no_answer" } }, [
                _vm._v("Prefer not to answer"),
              ]),
            ]),
            _vm._v(" "),
            _c("div", { attrs: { id: "submit-button" } }, [
              _c(
                "button",
                {
                  class: { disabled: _vm.formSubmitting },
                  attrs: { type: "submit" },
                },
                [_vm._v("submit")]
              ),
            ]),
          ]
        )
      : _vm._e(),
    _vm._v(" "),
    _c("div", { attrs: { id: "restart-button" } }, [
      _c("button", { on: { click: _vm.restart } }, [_vm._v("restart")]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }