import 'babel-polyfill';
import Vue from 'vue';
import VueRouter from 'vue-router';
import AsyncComputed from 'vue-async-computed';

import QuizApp from './quiz.vue';

Vue.use(VueRouter);
Vue.use(AsyncComputed);

Vue.directive('focus', {
  inserted: function (el) {
    el.focus()
  }
});

window.QuizApp = new Vue(QuizApp);
window.QuizApp.$mount('#quiz_app');
