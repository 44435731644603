var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c("div", { staticClass: "header" }, [
      _c("div", { staticClass: "header-inner" }, [
        _c("img", {
          attrs: {
            src: require("../images/header-logo.png").default,
            alt: "Denim Slade PhD",
          },
        }),
      ]),
    ]),
    _vm._v(" "),
    _c(
      "div",
      { attrs: { id: "quiz_app" } },
      [
        _vm.temperaments && _vm.questions && _vm.groups
          ? _c("router-view")
          : _vm._e(),
      ],
      1
    ),
    _vm._v(" "),
    _vm.temperaments && _vm.questions && _vm.groups
      ? _c("div", { staticClass: "footer" }, [_vm._m(0)])
      : _vm._e(),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "footer-inner" }, [
      _c("div", { staticClass: "footer-text" }, [
        _vm._v("© Copyright - Denim L. Slade"),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }